<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">发布课程使用</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem;">课程名称:</span>
              <el-input
                v-model="ruleForm.courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="使用区划" class="searchboxItem ci-full">
              <span class="itemLabel">使用区划:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="ruleForm.areaId"
              ></el-cascader>
            </div>
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="ruleForm.projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编码"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-top:10px">
            <div title="使用开始时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >使用开始时间:</span
              >
              <el-date-picker
                v-model="ruleForm.startDate"
                type="daterange"
                size="small"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <div title="使用结束时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >使用结束时间:</span
              >
              <el-date-picker
                v-model="ruleForm.endDate"
                type="daterange"
                size="small"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <div class="df" style="padding-right: 45px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="getExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                minWidth="180"
                prop="courseName"
              />
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
                minWidth="120"
                prop="trainLevelName"
              />
              <el-table-column
                label="课程使用学时"
                align="left"
                show-overflow-tooltip
                prop="compPeriod"
                width="140"
              ></el-table-column>
              <el-table-column
                label="使用区域"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
                width="160"
              ></el-table-column>
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                width="180"
              ></el-table-column>
              <el-table-column
                label="使用开始时间"
                align="left"
                show-overflow-tooltip
                prop="startDate"
                width="180"
              >
                <template slot-scope="scope">
                  {{ scope.row.startDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="使用结束时间"
                align="left"
                show-overflow-tooltip
                prop="endDate"
                width="180"
              >
                <template slot-scope="scope">
                  {{ scope.row.endDate | momentDate }}
                </template>
              </el-table-column>
               <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="settlementNum"
                width="120"
              ><template slot-scope="scope">
                  {{ scope.row.settlementNum? scope.row.settlementNum : '待确认' }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "set/TrajectoryList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索的数据
      ruleForm: {
        courseName: "", // 课程名称
        areaId: "", // 使用区域
        projectCode: "", // 班级编码
        startDate: [], // 开始时间截止
        endDate: [], // 结束时间截止
      },
      areatreeList: [], // 行政区划
      // 行政区划 - 数据规定
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.getareatree();
    this.getData(-1);
    this.getTableHeight();
  },
  watch: {},
 computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {},
  methods: {
    // 获取使用区域的下拉数据
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.ruleForm.courseName) {
        params.courseName = this.ruleForm.courseName;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.projectCode) {
        params.projectCode = this.ruleForm.projectCode;
      }
      if (this.ruleForm.startDate) {
        params.startDateBegin = this.ruleForm.startDate[0];
        params.startDateEnd = this.ruleForm.startDate[1];
      }
      if (this.ruleForm.endDate) {
        params.endDateBegin = this.ruleForm.endDate[0];
        params.endDateEnd = this.ruleForm.endDate[1];
      }
      this.doFetch({
        url: "/biz/buy/stat/use/course/page",
        params,
        pageNum,
      });
    },
    // 获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 60;
      }
      this.tableHeight = tHeight;
    },
    getExport() {
       const params = {
      };
      if (this.ruleForm.courseName) {
        params.courseName = this.ruleForm.courseName;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.projectCode) {
        params.projectCode = this.ruleForm.projectCode;
      }
      if (this.ruleForm.startDate) {
        params.startDateBegin = this.ruleForm.startDate[0];
        params.startDateEnd = this.ruleForm.startDate[1];
      }
      if (this.ruleForm.endDate) {
        params.endDateBegin = this.ruleForm.endDate[0];
        params.endDateEnd = this.ruleForm.endDate[1];
      }
          this.$post("/biz/buy/stat/use/course/export", params).then(
            (res) => {
              if (res.status == "0") {
                let list = res.data;
                if (!this.downloadItems.includes(list.taskId)) {
                  this.$store.dispatch("pushDownloadItems", list.taskId);
                } else {
                  this.$message.warning(
                    "[" + list.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              } else {
                this.$message.error(res.message);
              }
            }
          );
    }
  },
};
</script>
<style lang="less"></style>
